import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Errors from './Errors';
import { CircularProgress } from '@mui/material';

export default function SignUp() {
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [formData, setFormData] = useState({ email: '', password: '', firstName: '', lastName: '', passwordConfirmation: '' });

  const { email, password, firstName, lastName, passwordConfirmation } = formData;

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (event) => {
    try {
      setLoading(true);
      event.preventDefault();

      await axios.post(`${process.env.REACT_APP_BASE_URL}register`, {
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName
      });

      setMessage('A confirmation email has been sent to your inbox');
      setFormData({ email: '', password: '', firstName: '', lastName: '', passwordConfirmation: '' });
      setLoading(false);
    } catch (error) {
      setErrors(error.response.data.errors.map(error => error.stack));
      setLoading(false);
      setTimeout(() => {
        setErrors(null);
      }, 3000)
    } finally {
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Errors errors={errors} />
        { message && <Alert severity='info'>{ message }</Alert> }
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign up
        </Typography>
        <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete='given-name'
                name='firstName'
                required
                fullWidth
                id='firstName'
                label='First Name'
                autoFocus
                value={firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id='lastName'
                label='Last Name'
                name='lastName'
                autoComplete='family-name'
                value={lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                value={email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='new-password'
                value={password}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name='passwordConfirmation'
                label='Confirm Password'
                type='password'
                id='passwordConfirmation'
                autoComplete='new-password'
                value={passwordConfirmation}
                onChange={handleChange}
              />
              { password !== passwordConfirmation && <span style={{ color: 'red' }}>Passwords do not match</span> }
            </Grid>
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            disabled={loading || password !== passwordConfirmation}
          >
            Sign Up { loading && <CircularProgress size={20} /> }
          </Button>
          <Grid container justifyContent='flex-end'>
            <Grid item>
              <Link to='/signin'>
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
