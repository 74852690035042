import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Root from './routes/root';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import Map from './components/Map';
import Location from './components/Location';
import { AuthProvider } from './providers/AuthProvider';
import { Logout } from './components/Logout';
import { LocationsProvider } from './providers/LocationsProvider';
import ResetPassword from './components/ResetPassword';
import SubmitPassword from './components/SubmitPassword';
import ConfirmEmail from './components/ConfirmEmail';
import SubmitConfirmEmail from './components/SubmitConfirmEmail';
import { ThemeProvider } from '@emotion/react';
import { theme } from './utils/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Profile from './components/Profile';
import Profiles from './components/Profiles';
import ProfileLocations from './components/ProfileLocations';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/about",
        element: <About />
      },
      {
        path: "/contact",
        element: <Contact />
      },
      {
        path: "/map",
        element: <Map />
      },
      {
        path: "/map/:id",
        element: <Location />
      },
      {
        path: "/signup",
        element: <SignUp />
      },
      {
        path: "/signin",
        element: <SignIn />
      },
      {
        path: "/resetpassword",
        element: <ResetPassword />
      },
      {
        path: "/submitpassword/:token",
        element: <SubmitPassword />
      },
      {
        path: "/submitconfirmemail",
        element: <SubmitConfirmEmail />
      },
      {
        path: "/confirmemail/:token",
        element: <ConfirmEmail />
      },
      {
        path: "/logout",
        element: <Logout />
      },
      {
        path: "/profile",
        element: <Profile />
      },
      {
        path: "/profiles",
        element: <Profiles />
      },
      {
        path: "/profiles/:profileId",
        element: <ProfileLocations />
      }
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <LocationsProvider>
            <RouterProvider router={router} />
          </LocationsProvider>
        </AuthProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
