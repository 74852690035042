import React, { useState } from 'react';
import { Alert, Button } from '@mui/material';

export default function LocationImageForm({ handleImageUpload }) {
    const [locationPics, setLocationPics] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleLocationPicsChange = (e) => {
        const anyFilesOverSize = [...e.target.files].filter(f => (f.size / 1024) > 5120);
        console.log(anyFilesOverSize);
        if (anyFilesOverSize.length > 0) {
            setErrorMessage('Files must be less than 5 mb');
            setLocationPics([]);
            setTimeout(() => {
                setErrorMessage(null);
            }, 3000);
        } else {
            setLocationPics(e.target.files);
        }
    }

    const handleSubmit = async () => {
        await handleImageUpload(locationPics);
    }

    return (
        <>
            { errorMessage && <Alert severity="error">{ errorMessage }</Alert> }
            <input onChange={handleLocationPicsChange} name='locationPics' type='file' multiple />
            <br /><br />
            <Button onClick={handleSubmit} variant="contained" disabled={!locationPics.length > 0}>Submit</Button>
        </>
    )
}
