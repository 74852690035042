import React, { useState } from 'react';
import { useMap } from 'react-leaflet';
import { Modal, TextField, Button, CircularProgress, Typography, Checkbox } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Rating } from 'react-simple-star-rating';
import { Box } from '@mui/system';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function FormModal({ open, handleClose, onAddClick }) {
    const map = useMap();
    const [formData, setFormData] = useState({ location: '', details: '', rating: 0, lastVisited: null });
    const [hasVisited, setHasVisited] = useState(false);
    const [loading, setLoading] = useState(false);
    const { location, details, rating, lastVisited } = formData;

    const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const handleLastVisitedChange = (newValue) => setFormData({ ...formData, lastVisited: newValue });

    const handleAddClick = async () => {
        try {
            setLoading(true);
            await onAddClick(formData, map.getCenter());
            setLoading(false);
            setFormData({ location: '', details: '', rating: 0, lastVisited: null });
            setHasVisited(false);
            handleClose();
        } catch (error) {
            setLoading(false);
        }
    }

    const onCloseClick = () => {
        setHasVisited(false);
        setFormData({ location: '', details: '', rating: 0, lastVisited: null });
        handleClose();
    }

    return (
        <Modal
            open={open}
            onClose={onCloseClick}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box sx={style}>
                <TextField required value={location} style={{ marginBottom: '20px', width: '100%' }} label='Location' variant='filled' onChange={handleChange} name='location' />
                <TextField required value={details} multiline rows={4} style={{ marginBottom: '20px', width: '100%' }} label='Details' variant='filled' onChange={handleChange} name='details' />
                <Typography variant='p'>Have you visited?</Typography><Checkbox checked={hasVisited} onChange={(e) => setHasVisited(!hasVisited)} />
                { hasVisited && <DatePicker onChange={handleLastVisitedChange} value={lastVisited} /> }
                <Typography variant='h6'>Rating</Typography>
                <Rating
                    initialValue={rating}
                    allowFraction
                    onClick={(newRating) => setFormData({ ...formData, rating: newRating })}
                    size={28}
                />
                <br />
                <Button 
                    disabled={ location.length < 5 || details.length < 5 || loading } 
                    onClick={() => handleAddClick()} 
                    variant='contained'
                >Add { loading && <CircularProgress size={20} /> }</Button>
            </Box>
        </Modal>
    );
}
