import React from 'react';
import { Modal, Button, Typography } from '@mui/material';
import { Box } from '@mui/system';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export function DeleteConfirmation({ open, handleClose, handleConfirm }) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box sx={style}>
                <Typography component='div' variant='p' style={{ marginBottom: '20px' }}>Are you sure you want to delete this?</Typography>
                <div style={{ textAlign: 'center' }}>
                    <Button style={{ marginRight: '10px' }} variant='contained' onClick={() => handleConfirm()}>Delete</Button>
                    <Button variant='contained' onClick={handleClose}>Cancel</Button>
                </div>
            </Box>
        </Modal>
    )
}
