import React, { useState } from 'react';
import { Modal, TextField, Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function HightlightFormModal({ open, handleClose, onAddClick }) {
    const [highlight, setHighlight] = useState({ title: '', description: '' });
    const [loading, setLoading] = useState(false);

    const { title, description } = highlight;

    const handleAddClick = async () => {
        try {
            setLoading(true);
            await onAddClick(highlight);
            setLoading(false);
            setHighlight({ title: '', description: '' });
            handleClose();
        } catch (error) {
            setLoading(false);
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box sx={style}>
                <TextField 
                    required 
                    value={title} 
                    style={{ marginBottom: '20px', width: '100%' }} 
                    label='Title' 
                    variant='filled' 
                    onChange={e => setHighlight({ ...highlight, title: e.target.value})} />
                <TextField 
                    required 
                    value={description} 
                    style={{ marginBottom: '20px', width: '100%' }} 
                    label='Description' 
                    variant='filled' 
                    multiline
                    rows={4}
                    onChange={e => setHighlight({ ...highlight, description: e.target.value})} />
                <Button 
                    disabled={ title.length < 5 || description.length < 5 || loading } 
                    onClick={() => handleAddClick()} 
                    variant='contained'>
                        Add { loading && <CircularProgress size={20} /> }
                    </Button>
            </Box>
        </Modal>
    );
}
