import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Skeleton, Typography } from '@mui/material';
import { AuthContext } from '../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import ProfileCard from './ProfileCard';

export default function Profiles() {
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [profiles, setProfiles] = useState([]);

    useEffect(() => {
        if (!token) {
            navigate('/');
            return;
        }

        const fetchProfiles = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}profiles`, { headers: { Authorization: token } } );
                setProfiles(response.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error(error.message);
            }
        }

        fetchProfiles();
    }, [token]);

    return (
        <>
            <Typography sx={{ marginLeft: '40px', marginTop: '20px' }} variant='h2' component='div'>Profiles</Typography>
            <Grid container spacing={2} style={{ marginTop: '0px', paddingLeft: '40px', paddingRight: '40px' }}>
                {
                    loading ?
                    <Grid item xs={12}>
                        <Skeleton animation='wave' variant='rectangle' height={100} />
                    </Grid>
                    :
                    profiles.map((p, i) => {
                        return (
                            <Grid key={i} item xs={12} md={6}>
                                <ProfileCard avatar={p.user.avatar} firstName={p.user.firstName} lastName={p.user.lastName} bio={p.bio} _id={p._id} />
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}
