import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';

export default function ConfirmEmail() {
    const [message, setMessage] = useState('');
    const params = useParams();
    const token = params.token;

    useEffect(() => {
        const confirmEmail = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}confirmemail/${token}`);
                setMessage(response.data.message);   
            } catch (error) {
                setMessage(error.response.data.message);
            }
        }

        confirmEmail();
    }, []);

    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                }}
            >
                <Typography style={{ textAlign: 'center' }} component="h1" variant="h5">
                    { message }
                </Typography>
            </Box>
        </Container>
    )
}
