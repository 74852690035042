import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { AuthContext } from '../providers/AuthProvider';
import Errors from './Errors';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

export default function SignIn() {
  const { setToken } = useContext(AuthContext);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ email: '', password: '' });
  const navigate = useNavigate();

  const { email, password } = formData;

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]:e.target.value });

  const handleSubmit = async (event) => {
    try {
      setLoading(true);
      event.preventDefault();

      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}login`, { email: email, password: password });
      localStorage.setItem('token', response.data.token);
      setToken(response.data.token);
      setLoading(false);
      setFormData({ email: '', password: '' });
      navigate('/');
    } catch (error) {
      setErrors([ error.response.data.message ]);
      setLoading(false);
      setTimeout(() => {
        setErrors(null);
      }, 3000)
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Errors errors={errors} />
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                value={email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='new-password'
                value={password}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            Sign In { loading && <CircularProgress size={20} /> }
          </Button>
          <Grid container justifyContent='flex-end'>
            <Grid item>
              <Link to='/signup'>
                Don't have an account? Sign up
              </Link>
            </Grid>
            <Grid item>
              <Link to='/resetpassword'>
                Forgot your password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
