import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function ProfileCard({ avatar, lastName, firstName, bio, _id }) {
    return (
        <Card variant='outlined' style={{ marginBottom: '10px' }}>
            <CardContent>
                <Grid container>
                    <Grid item xs={2} sm={1}>
                        <Avatar src={avatar} sx={{ bgcolor: 'secondary.main' }} />
                    </Grid>
                    <Grid item xs={8} sm={9}>
                        <Typography variant='h5'>{ lastName }, { firstName }</Typography>
                        <Typography variant='p'>{ bio }</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Link style={{ color: '#fff', margin: 0, padding: 0 }} to={`/profiles/${_id}`}>
                            <Button variant='contained'>
                                <VisibilityIcon />
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
