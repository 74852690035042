import React, { useContext, useState, useEffect} from 'react';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { AuthContext } from '../providers/AuthProvider';
import Errors from './Errors';
import { CircularProgress, Skeleton } from '@mui/material';
import Following from './Following';
import { useNavigate } from 'react-router-dom';

export default function Profile() {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({ firstName: '', lastName: '', avatar: '', follows: [], followers: [] });
  const [formData, setFormData] = useState({ bio: '' });

  const { firstName, lastName, avatar, follows, followers } = profileData;
  const { bio } = formData;

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]:e.target.value });

  const handleSubmit = async (event) => {
    try {
      setLoading(true);
      event.preventDefault();

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}profile`, 
        { bio: bio },
        { headers: { Authorization: token } }
      );
      console.log(response);
      setLoading(false);
      setFormData({ bio: response.data.bio });
    } catch (error) {
      console.log(error.response.data);
      setErrors(error.response.data.errors.map(error => error.stack));
      setLoading(false);
      setTimeout(() => {
        setErrors(null);
      }, 3000)
    }
  };

  useEffect(() => {
    if (!token) {
      navigate('/');
      return;
    }

    const fetchProfile = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}profile`, { headers: { Authorization: token } } );
        console.log(response.data);
        setProfileData({ firstName: response.data.firstName, lastName: response.data.lastName, avatar: response.data.avatar, follows: response.data.follows, followers: response.data.followers });
        setFormData({ ...formData, bio: response.data.bio });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error.message);
      }
    };

    fetchProfile();
  }, [token]);

  return (
    <Container component='main' maxWidth='md'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          textAlign: 'center'
        }}
      >
        <Errors errors={errors} />
        <Avatar src={avatar} sx={{ m: 1, bgcolor: 'secondary.main', margin: 'auto' }} />        
        <Typography component='h1' variant='h5'>
          Profile
        </Typography>
        {
          loading ?
          <Skeleton variant='rectangular' animation='wave' height={50} />
          :
          <Typography component='h2' variant='h6'>
            { lastName }, { firstName }
          </Typography>
        }
        <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                rows={4}
                multiline
                required
                fullWidth
                id='bio'
                label='Bio'
                name='bio'
                autoComplete='bio'
                value={bio}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            Save { loading && <CircularProgress size={20} /> }
          </Button>
        </Box>
        <Typography variant='h3'>Following</Typography>
        { loading && <Skeleton variant='rectangular' animation='wave' height={100} /> }
        <Following profiles={follows} />
        <Typography variant='h3'>Followers</Typography>
        { loading && <Skeleton variant='rectangular' animation='wave' height={100} /> }
        <Following profiles={followers} />
      </Box>
    </Container>
  );
}
