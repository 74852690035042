import React, { useState } from 'react';
import { Button, Card, CardContent, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { DeleteConfirmation } from './DeleteConfirmation';

export default function HighlightCard({ highlight, onHighlightRemoveClick, onHighlightUpdateClick, loading, i }) {
    const [editable, setEditable] = useState(false);
    const [formData, setFormData] = useState({ title: highlight.title, description: highlight.description });
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const { title, description } = formData;

    const toggleEdit = () => {
        if (editable)
            handleSaveClick();
        setEditable(!editable);
    }

    const handleSaveClick = () => { 
        onHighlightUpdateClick(formData);
    }

    const handleDeleteConfirmClose = () => {
        setDeleteConfirmOpen(false);
    }

    const handleDeleteConfirmOpen = () => {
        setDeleteConfirmOpen(true);
    }

    const onRemoveClick = () => {
        onHighlightRemoveClick(title);
    }

    const handleChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    return (
        <Grid key={i} item xs={12} md={6}>
            <Card variant='outlined'>
                <CardContent>
                    {
                         editable ? 
                        <>
                            <TextField required value={title} style={{ marginTop: '40px', marginBottom: '10px', width: '100%' }} label='Title' variant='filled' onChange={handleChange} name='title' />
                            <TextField required value={description} multiline rows={4} style={{ marginBottom: '20px', width: '100%' }} label='Description' variant='filled' onChange={handleChange} name='description' />
                        </>
                        :
                        <>
                            <Typography variant='h6'>{ title }</Typography>
                            <Typography component='div' variant='p'>{ description }</Typography>
                        </>
                    }
                    <Button onClick={() => handleDeleteConfirmOpen()}><DeleteIcon /></Button>
                    <Button variant='contained' disabled={loading} style={{ marginLeft: 3 }} onClick={() => toggleEdit()}>
                        {  editable ? <SaveIcon /> : <EditIcon /> } { loading && <CircularProgress size={20} /> }
                    </Button>
                </CardContent>
            </Card>
            <DeleteConfirmation open={deleteConfirmOpen} handleClose={handleDeleteConfirmClose} handleConfirm={onRemoveClick} />
        </Grid>
    )
}
