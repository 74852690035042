import React from 'react';
import { Grid, Typography } from '@mui/material';
import HighlightCard from './HighlightCard';

export default function HighlightsCards({ highlights, onHighlightRemoveClick, onHighlightUpdateClick, loading }) {
    return (
        <>
            <Typography variant='h4' style={{ marginBottom: '10px' }}>Location Highlights:</Typography>
            <Grid container spacing={2}>
                {
                    highlights.map((h, i) => {
                        return (
                            <HighlightCard key={i} i={i} highlight={h} onHighlightRemoveClick={onHighlightRemoveClick} onHighlightUpdateClick={onHighlightUpdateClick} loading={loading} />
                        );
                    })
                }
            </Grid>
        </>
    )
}
