import { createContext, useState } from "react";
import axios from "axios";

export const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [token, setToken] = useState(() => {
        const token = localStorage.getItem('token');
        return token ? token : null;
    });

    const validateToken = async () => {
        let user = null;
        try {
            if (token) {
                const response = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}validate`,
                    { headers: { Authorization: token } }
                );
        
                user = response.data;
            }
        } catch (error) {
            setToken(null);
            localStorage.removeItem('token');
        }

        return user;
    }

    return (
        <AuthContext.Provider value={{ token, setToken, validateToken }}>
            { children }
        </AuthContext.Provider>
    );
}
