import React from 'react';
import { Grid } from '@mui/material';
import ProfileCard from './ProfileCard';

export default function Following({ profiles }) {
    return (
        <>
            <Grid container spacing={2} style={{ marginTop: '0px', paddingLeft: '40px', paddingRight: '40px' }}>
                {
                    profiles.map((p, i) => {
                        return (
                            <Grid key={i} item xs={12} md={6}>
                                <ProfileCard avatar={p.user.avatar} firstName={p.user.firstName} lastName={p.user.lastName} bio={p.bio} _id={p._id} />
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}

