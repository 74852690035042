import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Typography, TextField, Skeleton, CircularProgress, Checkbox } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../providers/AuthProvider';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { LocationsContext } from '../providers/LocationsProvider';
import HighlightsCards from './HighlightsCards';
import HightlightFormModal from './HighlightFormModal';
import { Box } from '@mui/system';
import { Rating } from 'react-simple-star-rating';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { DatePicker } from '@mui/x-date-pickers';
import LocationImages from './LocationImages';
import LocationImageForm from './LocationImageForm';

export default function Location() {
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();
    const { setPosition } = useContext(LocationsContext);
    const [hasVisited, setHasVisited] = useState(false);
    const [loc, setLoc] = useState({ lastVisited: null, rating: null, location: '', details: '', lat: null, lng: null, highlights: [], images: [] });
    const [editable, setEditable] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const params = useParams();

    const { rating, location, details, lat, lng, _id, highlights, lastVisited, images } = loc;

    const handleEditClick = async () => {
        setLoading(true);
        if (editable) {
            await setPosition(_id, loc);
        }
        setLoading(false);
        setEditable(!editable);
    }

    const handleCancelClick = async () => {
        const loadLocation = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}locations/${params.id}`, { headers: { 'Authorization': token } });
            setLoc({ ...response.data, lastVisited: dayjs(response.data.lastVisited) });
            setHasVisited(!!response.data.lastVisited);
        }

        await loadLocation();
        setEditable(false);
    }

    const handleAddHighlightClick = async (highlight) => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}locations/${loc._id}/highlights`,
                {title: highlight.title, description: highlight.description},
                { headers: { Authorization: token } }
            );
            setLoc(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    const handleRemoveHighlightClick = async (highlight) => {
        try {
            setLoading(true);
            const response = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}locations/${loc._id}/highlights/${highlight}`,
                { headers: { Authorization: token } }
            );
            setLoc(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const handleLastVisitedChange = (newValue) => setLoc({ ...loc, lastVisited: newValue });

    const handleUpdateHightlightClick = async (body) => {
        try {
            setLoading(true);
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}locations/${loc._id}/highlights/${body.title}`,
                body,
                { headers: { Authorization: token } }
            );
            setLoc(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error.message);
        }
    }

    const handleImageUpload = async (locationPics) => {
        const uploadedImages = [];
        try {
            setLoading(true);
            for (const file of locationPics) {
                console.log(file);
                var formData = new FormData();
                formData.append("file", file);
                let res = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}locations/${loc._id}/image/upload`, 
                    formData, 
                    { headers: { Authorization: token } }
                );
                const newImageUrl = res.data.link;
                uploadedImages.push(newImageUrl);
            }
            setLoc({ ...loc, images: [...loc.images, ...uploadedImages ] });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error.message);
        }
    }

    const handleImageRemove = async (imageUrl) => {
        try {
            setLoading(true);
            const res = await axios.put(
                `${process.env.REACT_APP_BASE_URL}locations/${loc._id}/image/remove`,
                { imageUrl },
                { headers: { Authorization: token } }
            );
            setLoc(res.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const handleModalClose = () => setModalOpen(false);

    const handleChange = e => setLoc({ ...loc, [e.target.name]: e.target.value });

    useEffect(() => {
        if (!token) {
            navigate('/');
            return;
        }

        const loadLocation = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}locations/${params.id}`, { headers: { 'Authorization': token } });
            setLoc({ ...response.data, lastVisited: dayjs(response.data.lastVisited) });
            setHasVisited(!!response.data.lastVisited);
        }

        loadLocation();
    }, [token]);

    return (
        <>
            {
                lng
                ?
                <>
                    <MapContainer center={[lat, lng]} zoom={13} scrollWheelZoom={false}
                        style={{ height:'500px',width:'100%',backgroundColor:'red',marginBottom:'10px' }}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker
                                draggable={false}
                                position={{ lat: lat, lng: lng }} />
                    </MapContainer>
                    <Box style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                        {
                            editable 
                            ?
                            <>
                                <TextField required value={location} style={{ marginTop: '40px', marginBottom: '10px', width: '100%' }} label='Location' variant='filled' onChange={handleChange} name='location' />
                                <TextField required value={details} multiline rows={4} style={{ marginBottom: '20px', width: '100%' }} label='Details' variant='filled' onChange={handleChange} name='details' />
                                <Typography variant='p'>Have you visited?</Typography><Checkbox checked={hasVisited} onChange={(e) => setHasVisited(!hasVisited) } />
                                { hasVisited && <DatePicker onChange={handleLastVisitedChange} value={lastVisited} /> }
                                <br />
                            </>
                            :
                            <>
                                <Typography variant='h4' style={{ marginTop: '40px' }}>{ location }</Typography>
                                <Typography variant='p' component='div'>{ details }</Typography>
                                <Typography variant='p' component='div'><strong>Last Visited:</strong> { dayjs(lastVisited).format('MM/DD/YYYY') }</Typography>
                            </>
                        }
                        <Rating initialValue={rating} allowFraction size={28} onClick={async (newRating) => await setPosition(_id, { ...loc, rating: newRating })} />
                        <br />
                        <Button 
                            disabled={ location.length < 5 || details.length < 5 || loading } 
                            onClick={handleEditClick} 
                            style={{ marginTop: '20px' }}
                            variant='contained'>{ editable ? <SaveIcon /> : <EditIcon /> } { loading && <CircularProgress size={20} /> }</Button>
                        {
                            editable &&
                            <Button
                                onClick={handleCancelClick}
                                style={{ marginTop: '20px', marginLeft: '10px' }}
                                variant='contained'><CancelIcon /></Button>
                        }
                        <br /><br />
                        <HighlightsCards onHighlightRemoveClick={handleRemoveHighlightClick} onHighlightUpdateClick={handleUpdateHightlightClick} highlights={highlights} loading={loading} />
                        <br />
                        <Button variant='contained' onClick={() => setModalOpen(true)}><AddIcon /></Button>
                        <br /><br />
                        <LocationImages images={images} handleImageRemove={handleImageRemove} />
                        <br />
                        <br />
                        <LocationImageForm handleImageUpload={handleImageUpload} />
                        <HightlightFormModal open={modalOpen} handleClose={handleModalClose} onAddClick={handleAddHighlightClick} />
                    </Box>
                </>
                :
                <>
                    <Skeleton style={{ marginTop: '40px', marginBottom: '10px' }}  animation='wave' height={40} />
                    <Skeleton style={{ marginBottom: '20px' }} animation='wave' height={40} />
                    <Skeleton style={{ marginBottom: '20px' }} animation='wave' height={500} />
                </>
            }
        </>
    );
}
