import React, { useContext, useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ExploreIcon from '@mui/icons-material/Explore';
import PlaceIcon from '@mui/icons-material/Place';
import ShareIcon from '@mui/icons-material/Share';
import { AuthContext } from '../providers/AuthProvider';

export default function Home() {
    const { token } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (token)
            navigate('/map');
    });

    return (
        <>
            <div className='demowrap'>
                <div className='demoContent'>
                    <Typography style={{ paddingTop: '50px' }} variant='h2'>Nomad Tracker</Typography>
                    <Typography style={{ marginTop: '20px' }} variant='p' component='div'>The defacto tool for managing your travel bucket list</Typography>
                    <div style={{ marginTop: '20px', paddingBottom: '50px', textAlign: 'center' }}>
                        <Button style={{ marginRight: '10px' }} variant='contained'>
                            <Link style={{ textDecoration: 'none', color: '#fff' }} to='/signup'>Sign Up</Link>
                        </Button>
                        <Button style={{ marginLeft: '10px' }} variant='contained'>
                            <Link style={{ textDecoration: 'none', color: '#fff' }} to='/signin'>Sign In</Link>
                        </Button>
                    </div>
                </div>
            </div>
            <Grid style={{ marginTop: '10px', paddingLeft: '40px', paddingRight: '40px' }} container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Typography variant='h4'><ExploreIcon /> Explore</Typography>
                    <Typography variant='p'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant='h4'><PlaceIcon /> Track</Typography>
                    <Typography variant='p'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant='h4'><ShareIcon /> Share</Typography>
                    <Typography variant='p'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Typography>
                </Grid>
            </Grid>
        </>
    )
}
