import React from 'react';
import Alert from '@mui/material/Alert';

export default function Errors({ errors }) {
    return (
        <>
            {
                errors &&
                    errors.map((error, i) => <Alert key={i} severity='error'>{ error }</Alert>)
            }
        </>
    )
}
