import React, { useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Alert } from '@mui/material';
import { useParams } from 'react-router-dom';

export default function SubmitPassword() {
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const params = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}resetsubmit`, { password, token: params.token });
            setPassword('');
            const returnMessage = response.data.message;
            setMessage(returnMessage);
        } catch (error) {
            const returnMessage = error.response.data.message;
            setMessage(returnMessage);
        } finally {
            setLoading(false);
            setTimeout(() => {
                setMessage(null);
            }, 3000);
        }
    }

    return (
        <Container component='main' maxWidth='lg'>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                { message && <Alert severity='info'>{ message }</Alert> }
                <Typography component='h1' variant='h5'>
                    Submit New Password
                </Typography>
                <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id='password'
                                label='Password'
                                name='password'
                                autoComplete='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                sx={{ mt: 0, mb: 2 }}
                                disabled={ password.length < 3 || loading }
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}
