import React, { useState } from 'react';
import { Button, Card, CardActions, Grid, Modal, Typography, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '70%', md: '50%', lg: '35%', xl: '30%' },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function LocationImages({ images, handleImageRemove }) {
    const [open, setOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    const handleViewClick = (index) =>{
        setSelectedImageIndex(index);
        setOpen(true);
    }

    const handleClose = () => setOpen(false);

    const handleImageIndexNext = () => {
        if (selectedImageIndex < images.length - 1)
            setSelectedImageIndex(selectedImageIndex + 1);
    }

    const handleImageIndexPrev = () => {
        if (selectedImageIndex > 0)
            setSelectedImageIndex(selectedImageIndex - 1);
    }

    return (
        <>
            <Typography variant='h4' style={{ marginBottom: '10px' }}>Location Images:</Typography>
            <Grid container spacing={2}>
                {
                    images.map((p, i) => {
                        return (
                            <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                                <Card>
                                    <img src={p} />
                                    <CardActions>
                                        <Button size='small' onClick={() => handleImageRemove(p)}><DeleteIcon /></Button>
                                        <Button size='small' onClick={() => handleViewClick(i)}><VisibilityIcon /></Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        );
                    })
                }
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={style}>
                    <img src={images[selectedImageIndex]} />
                    <div style={{ textAlign: 'center' }}>
                        <Button disabled={selectedImageIndex === 0} style={{ margin: 'auto' }} size='small' onClick={handleImageIndexPrev}><ArrowBackIosIcon /></Button>
                        <Button disabled={selectedImageIndex === images.length - 1} style={{ margin: 'auto' }} size='small' onClick={handleImageIndexNext}><ArrowForwardIosIcon /></Button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}
