import React, { useContext, useState } from 'react';
import { Card, CardContent, Grid, Skeleton, Typography, Select, MenuItem, FormControl, InputLabel, Box, Pagination, TextField, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocationsContext } from '../providers/LocationsProvider';
import { Rating } from 'react-simple-star-rating';

export default function LocationCards({ onCardClick }) {
    const [searchString, setSearchString] = useState('');
    const [activeSearch, setActiveSearch] = useState(false);
    const { loadLocations, loading, locCounts, changeLocsCount, locsPerPage, pageLocations, sortOrder, sortLocations, sortOrders, pageCount, currentPage, changePage, searchLocations } = useContext(LocationsContext);
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchSubmit()
        }
    }
    
    const handlePageChange = (e, value) => {
        changePage(value);
    }

    const handleSearchSubmit = () => {
        searchLocations(searchString);
        setActiveSearch(true);
    }

    const handleSearchCancel = () => {
        setSearchString('');
        setActiveSearch(false);
        loadLocations();
    }

    return (
        <>
        {
            loading 
            ?
            <>
                <Skeleton animation='wave' width='100%' height={120} />
                <Skeleton animation='wave' width='100%' height={120} />
                <Skeleton animation='wave' width='100%' height={120} />
            </>
            :
            <Grid container spacing={2} style={{ marginTop: '0px', paddingLeft: '40px', paddingRight: '40px' }}>
                <Grid item xs={4}>
                    <Box sx={{ maxWidth: 200 }}>
                        <FormControl fullWidth>
                            <InputLabel id='sort-label'>Sort By</InputLabel>
                            <Select
                                labelId='sort-label'
                                id='sort'
                                value={sortOrder}
                                label='Sort By'
                                onChange={(e) => sortLocations(e.target.value)}
                            >
                                {
                                    sortOrders.map(o => <MenuItem key={o} value={o}>{ o }</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <Box>
                        <FormControl>
                            <TextField id='search' value={searchString} label='Search' onKeyPress={handleKeyPress} onChange={(e) => setSearchString(e.target.value)} />
                        </FormControl>
                        {
                            activeSearch &&
                            <FormControl>
                                <Button onClick={handleSearchCancel} variant='contained' style={{ height: '55px', marginLeft: '5px' }}><DeleteIcon /></Button>
                            </FormControl>
                        }
                    </Box>
                </Grid>
                { pageLocations.map((loc, i) => {
                    return (
                        <Grid key={i} item xs={12} md={6}>
                            <Card variant='outlined' style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => onCardClick([loc.lat, loc.lng], loc.id)}>
                                <CardContent>
                                    <Typography variant='h6'>{ loc.location }</Typography>
                                    <Typography variant='p' component='div'>{ loc.details }</Typography>
                                    <Rating allowFraction readonly initialValue={loc.rating} size={24} />
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                }) }
                <Grid container>
                    <Grid item xs={3}>
                        <Box sx={{ maxWidth: 200 }}>
                            <FormControl variant='standard' fullWidth>
                                <InputLabel id='count-label'>Per Page</InputLabel>
                                <Select
                                    labelId='count-label'
                                    id='count'
                                    value={locsPerPage}
                                    label='Per Page'
                                    onChange={(e) => changeLocsCount(e.target.value)}
                                >
                                    {
                                        locCounts.map(c => <MenuItem key={c} value={c}>{ c }</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={9}>
                        <Pagination size='large' count={pageCount} page={currentPage} onChange={handlePageChange} color='primary' />
                    </Grid>
                </Grid>
            </Grid>
        }
        </>
    );
}
