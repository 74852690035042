import { createContext, useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "./AuthProvider";

export const LocationsContext = createContext();

export function LocationsProvider({ children }) {
    const sortOrders = [
        'Rating: High to Low',
        'Rating: Low to High',
        'Alphabetical: Asc',
        'Alphabetical: Desc'
    ];

    const locCounts = [ 6, 12, 24 ];

    const { token } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [locations, setLocations] = useState([]);
    const [pageLocations, setPageLocations] = useState([]);
    const [sortOrder, setSortOrder] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const [locsPerPage, setLocsPerPage] = useState(6);
    const [currentPage, setCurrentPage] = useState(1);

    const loadLocations = async () => {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}locations`, { headers: { Authorization: token } });
        setLocations(response.data.map(l => ({ ...l, id: l._id })));
        setPageLocations(response.data.slice(0, locsPerPage).map(l => ({ ...l, id: l._id })));
        setPageCount(Math.ceil(response.data.length / locsPerPage));
        setLoading(false);
    };

    const sortLocations = (sort) => {
        const copyLocations = [...locations];
        setCurrentPage(1);
        if (sort === 'Rating: High to Low') {
            copyLocations.sort((a, b) => (a.rating > b.rating) ? -1 : 1);
        } else if (sort === 'Rating: Low to High') {
            copyLocations.sort((a, b) => (a.rating > b.rating) ? 1 : -1);
        } else if (sort === 'Alphabetical: Desc') {
            copyLocations.sort((a, b) => (a.location > b.location) ? -1 : 1);
        } else if (sort === 'Alphabetical: Asc') {
            copyLocations.sort((a, b) => (a.location > b.location) ? 1 : -1);
        }
        setLocations(copyLocations);
        setPageLocations(copyLocations.slice(0, locsPerPage));
        setSortOrder(sort);
    }

    const searchLocations = (query) => {
        const copyLocations = [...locations];
        setCurrentPage(1);
        const searchedLocations = copyLocations.filter(l => l.location.toLowerCase().includes(query.toLowerCase()));

        setLocations(searchedLocations);
        setPageLocations(searchedLocations.slice(0, locsPerPage));
        setPageCount(Math.ceil(searchedLocations.length / locsPerPage));
    }

    const loadPageLocations = () => {
        setLoading(true);
        const copyLocations = [...locations];
        setPageLocations(copyLocations.slice(0, locsPerPage).map(l => ({ ...l })));
        setLoading(false);
    }

    const changePage = (page) => {
        const copyLocations = [...locations];
        setCurrentPage(page);
        setPageLocations(copyLocations.slice((page - 1) * locsPerPage, locsPerPage * page));
    };

    const changeLocsCount = (count) => {
        setLocsPerPage(count);
    };

    const handleAddClick = async ({ details, location, rating, lastVisited }, center) => {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}locations`, { ...center, details, location, rating, lastVisited }, { headers: { Authorization: token } });
        const returnedLocation = response.data;
        setLocations([ { ...returnedLocation, id: response.data._id }, ...locations ]);
        setPageCount(Math.ceil((locations.length + 1) / locsPerPage));
    };

    const handleRemoveClick = async (index) => {
        setLoading(true);
        await axios.delete(`${process.env.REACT_APP_BASE_URL}locations/${index}`, { headers: { Authorization: token } });
        setLocations(locations.filter(loc => loc.id !== index));
        const copyLocations = [...locations.filter(loc => loc.id !== index)];
        if (pageCount === currentPage && pageLocations.length === 1 && currentPage !== 1) {
            setPageLocations(copyLocations.slice((currentPage - 2) * locsPerPage, locsPerPage * currentPage));
            setCurrentPage(currentPage - 1);
            setPageCount(pageCount - 1);
        } else {
            setPageLocations(copyLocations.slice((currentPage - 1) * locsPerPage, locsPerPage * currentPage));
        }
        setLoading(false);
    };

    const setPosition = async (index, location) => {
        setLocations([ ...locations.map((loc) => loc.id === index ? { ...loc, lat: location.lat, lng: location.lng } : loc) ]);
        await axios.put(`${process.env.REACT_APP_BASE_URL}locations/${index}`, location, { headers: { Authorization: token } });
    };

    return (
        <LocationsContext.Provider value={{ loadPageLocations, locCounts, locsPerPage, changeLocsCount, locations, pageLocations, loadLocations, handleAddClick, handleRemoveClick, setPosition, loading, sortOrder, sortLocations, sortOrders, pageCount, currentPage, changePage, searchLocations }}>
            { children }
        </LocationsContext.Provider>
    );
}
