import React, { useRef, useState, useMemo, useEffect } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { Typography, Button } from '@mui/material';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import PushPinIcon from '@mui/icons-material/PushPin';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';

export default function DraggableMarker({ position, setPosition, index, onRemoveClick, openPopup }) {
    const [draggable, setDraggable] = useState(false);

    const markerRef = useRef(null);
    const eventHandlers = useMemo(() => ({
        dragend() {
            const marker = markerRef.current;
            if (marker != null && draggable) {
                setPosition(index, { ...marker.getLatLng(), details: position.details, location: position.location, rating: position.rating, lastVisited: position.lastVisited });
                setDraggable(false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [draggable]);

    const toggleDraggable = () => {
        setDraggable(!draggable);
    };

    useEffect(() => {
        if (openPopup)
            markerRef.current.openPopup();
    }, [openPopup]);

    return (
        <Marker
            draggable={draggable}
            eventHandlers={eventHandlers}
            position={{ lat: position.lat, lng: position.lng }}
            ref={markerRef}>
            <Popup minWidth={90}>
                <Typography variant='h6'>{ position.location }</Typography>
                <Typography variant='p' component='div'>{ position.details }</Typography>
                <Rating readonly allowFraction initialValue={position.rating} size={20} />
                <br />
                <Link style={{ color: '#fff', margin: 0, padding: 0 }} to={`/map/${position.id}`}>
                    <Button sx={{ mr: 1 }} variant='contained'>
                        <VisibilityIcon />
                    </Button>
                </Link>
                <Button sx={{ mr: 1 }} variant='contained' onClick={toggleDraggable}>
                {draggable
                    ? <PushPinIcon />
                    : <EditLocationIcon />}
                </Button>
                <Button variant='contained' onClick={() => onRemoveClick(index)}>
                    <DeleteIcon />
                </Button>
            </Popup>
        </Marker>
    );
}
