import * as React from 'react';
import { Link, Outlet } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { AuthContext } from '../providers/AuthProvider';
import { Footer } from '../components/Footer';
import logo from '../logo.png';

const drawerWidth = 240;

export default function Root(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { token, validateToken } = React.useContext(AuthContext);

  React.useEffect(() => {
    validateToken();
  }, []);

  const authNav = [
    { page: 'Home', link: '/' },
    { page: 'Map', link: '/map' },
    { page: 'Profile', link: '/profile' },
    { page: 'Profiles', link: '/profiles' },
    { page: 'Logout', link: '/logout' }
  ];
  const pubNav = [
    { page: 'Home', link: '/' },
    { page: 'Sign Up', link: '/signup' },
    { page: 'Sign In', link: '/signin' },
  ];

  const navItems = token ? authNav : pubNav;
                          

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Nomad Tracker
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.page} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <Link style={{ textDecoration: 'none', color: '#000' }} to={item.link}>
                <ListItemText primary={item.page} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{ flexGrow: 1, display: { sm: 'block' }, paddingLeft: { xs: '30%', sm: '0' } }}
          >
            <img alt="logo" style={{ maxWidth: '70px', marginTop: '5px' }} src={logo} />
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button key={item.page} sx={{ color: '#fff' }}>
                <Link style={{ textDecoration: 'none', color: '#fff' }} to={item.link}>
                  {item.page}
                </Link>
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box id="detail" component="main" style={{ margin: 'auto', width: '100%' }}>
        <Toolbar />
        <Outlet />
        <Footer />
      </Box>
    </Box>
  );
}
