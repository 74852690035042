import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../providers/AuthProvider';

export function Logout() {
    const navigate = useNavigate();
    const { setToken } = useContext(AuthContext);

    useEffect(() => {
        setToken(null);
        localStorage.removeItem('token');
        navigate('/');
    })

    return null;
}
