
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "#6d7560",
                    color: "#fff"
                }
            }
        }
    },
    palette: {
        primary: {
            main: "#6d7560"
        },
        secondary: {
            main: "#efe6e2"
        }
    }
});
