import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Skeleton, Typography } from '@mui/material';
import { AuthContext } from '../providers/AuthProvider';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import axios from 'axios';

function SetCenter({ center }) {
    const map = useMap();

    useEffect(() => {
        map.setView(center);
    }, [center]);
}

export default function ProfileLocations() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [profile, setProfile] = useState({ firstName: '', lastName: '' });
    const [userFollows, setUserFollows] = useState(false);
    const [center, setCenter] = useState([51.505, -0.09]);
    const { profileId } = useParams();
    const { token } = useContext(AuthContext);

    const { firstName, lastName } = profile;

    const handleFollow = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}follow/${profileId}`, {}, { headers: { Authorization: token } });
            setUserFollows(true);
        } catch (error) {
            console.error(error.message);
        }
    }

    const handleUnFollow = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BASE_URL}unfollow/${profileId}`, {}, { headers: { Authorization: token } });
            setUserFollows(false);
        } catch (error) {
            console.error(error.message);
        }
    }

    useEffect(() => {
        if (!token) {
            navigate('/');
            return;
        }
        
        const fetchProfileLocations = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}profiles/${profileId}`, { headers: { Authorization: token } });
                setLocations(response.data.locations);
                setCenter([response.data.locations[0].lat, response.data.locations[0].lng]);
                setProfile({ firstName: response.data.profile.user.firstName, lastName: response.data.profile.user.lastName });
                setUserFollows(response.data.userFollows);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error(error.message);
            }
        }

        fetchProfileLocations();
    }, [token])

    return (
        <>
            <MapContainer scrollWheelZoom center={center} zoom={8}
                style={{ height: '500px',width: '100%', backgroundColor: 'red', marginBottom: '10px' }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {
                    locations.map((loc, i) => <Marker key={i} position={[loc.lat, loc.lng]} />)
                }
                <SetCenter center={center} />
            </MapContainer>
            {
                loading ?
                <Skeleton animation='wave' variant='rectangle' height={50} />
                :
                <Typography variant='h2' style={{ textAlign: 'center' }}>{ lastName }, { firstName }</Typography>
            }
            <div style={{ textAlign: 'center' }}>
                {
                    userFollows ?
                    <Button onClick={() => handleUnFollow()} variant='contained'>Unfollow</Button>
                    :
                    <Button onClick={() => handleFollow()} variant='contained'>Follow</Button>
                }
            </div>
        </>
    )
}
