import React from 'react';
import { Box } from '@mui/system';
import { Container, Grid, Typography } from '@mui/material';

export function Footer() {
    return (
        <Box
            sx={{
                width: '100%',
                height: 'auto',
                backgroundColor: 'secondary.main',
                paddingTop: '1rem',
                paddingBottom: '1rem',
                marginTop: '1rem'
            }}
            >
            <Container maxWidth='lg'>
                <Grid container direction='column' alignItems='center'>
                <Grid item xs={12}>
                    <Typography color='black' variant='h5'>
                        Wild Nomadic
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography color='textSecondary' variant='subtitle1'>
                    {`${new Date().getFullYear()}`}
                    </Typography>
                </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
