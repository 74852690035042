import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import Button from '@mui/material/Button';
import { AuthContext } from '../providers/AuthProvider';
import LocationCards from './LocationCards';
import DraggableMarker from './DraggableMarker';
import FormModal from './LocationFormModal';
import { LocationsContext } from '../providers/LocationsProvider';
import { Skeleton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DeleteConfirmation } from './DeleteConfirmation';

function SetCenter({ center }) {
    const map = useMap();

    useEffect(() => {
        map.setView(center);
    }, [center]);
}

export default function Map() {
    const { 
        locations, 
        loadPageLocations, 
        locsPerPage, 
        loadLocations, 
        handleAddClick, 
        handleRemoveClick, 
        setPosition, 
        loading 
    } = useContext(LocationsContext);
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [center, setCenter] = useState([51.505, -0.09]);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState(-1);
    const { token } = useContext(AuthContext);

    const handleCardClick = (center, locId) => {
        setSelectedMarker(locId);
        setCenter(center);
        window.scrollTo(0, 0);
    }

    const handleModalOpen = () => {
        setModalOpen(true);
    }
    
    const handleModalClose = () => {
        setModalOpen(false);
    }

    const handleDeleteConfirmClose = () => {
        setDeleteConfirmOpen(false);
    }

    const handleDeleteConfirmOpen = (index) => {
        setDeleteIndex(index);
        setDeleteConfirmOpen(true);
    }

    const onRemoveClick = () => {
        handleRemoveClick(deleteIndex);
        setDeleteIndex(-1);
        handleDeleteConfirmClose();
    }

    useEffect(() => {
        if (!token) {
            navigate('/');
            return;
        }

        loadLocations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        loadPageLocations();
    }, [locsPerPage])

    useEffect(() => {
        if (locations.length > 0) {
            setCenter([ locations[0].lat, locations[0].lng ]);
            loadPageLocations();
        }
    }, [locations]);

    return (
        <>
            {
                loading
                ?
                <Skeleton animation="wave" style={{ marginBottom: '10px' }} variant='rectangular' height={500} width='100%' />
                :
                <>
                    <MapContainer scrollWheelZoom center={center} zoom={8}
                            style={{ height:"500px",width:'100%',backgroundColor:"red",marginBottom:'10px' }}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <FormModal onAddClick={handleAddClick} open={modalOpen} handleClose={handleModalClose} />
                        {
                            locations.map((loc, i) => <DraggableMarker openPopup={loc.id === selectedMarker} key={i} index={loc.id} position={loc} onRemoveClick={handleDeleteConfirmOpen} setPosition={setPosition}></DraggableMarker>)
                        }
                        <SetCenter center={center} />
                    </MapContainer>
                </>
            }
            <Button onClick={() => handleModalOpen()} variant='contained' style={{ marginBottom: '10px', marginLeft: '40px' }}><AddIcon /></Button>
            <LocationCards onCardClick={handleCardClick} />
            <DeleteConfirmation open={deleteConfirmOpen} handleClose={handleDeleteConfirmClose} handleConfirm={onRemoveClick} />
        </>
    )
}
